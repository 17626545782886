@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.animateScroll {
    animation: scroll 30s linear infinite;
}