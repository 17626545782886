/* Define CSS variables for square size and gap */
:root {
    --square-size: 60px;
    /* Adjusted to a smaller size */
    --gap-size: 0.2rem;
}

/* Container for the grid */
.container {
    pointer-events: none;
    width: 60%;
    max-width: 40rem;
    margin: 0 auto;
    background-color: var(--background);
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 40;
    height: calc((80px * 2) + 6 * 0.2rem);
    overflow-y: auto;
    box-sizing: content-box;
    scroll-behavior: smooth;

    /* Modern scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: rgba(100, 100, 100, 0.5) transparent;
}

/* Modern scrollbar styling for WebKit browsers */
.container::-webkit-scrollbar {
    width: 8px;
}

.container::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px 0;
}

.container::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5);
    border-radius: 4px;
}

.container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(100, 100, 100, 0.7);
}

/* Grid layout */
.grid {
    pointer-events: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 0.2rem;
}

/* Square items */
.square {
    pointer-events: auto;
    background-color: #f0f0f0dc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s ease;
    cursor: pointer;
    position: relative;
    aspect-ratio: 1 / 1;
    overflow: hidden;
}

/* Hover effect for the square */
.square:hover {
    background-color: #d1e0f4;
}

/* Item info containing the icon/image */
.itemInfo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

/* Image styling for items that have an image */
.itemIcon {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* Placeholder styling when no image is available */
.iconPlaceholder {
    width: 50%;
    height: 50%;
    background-color: #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* General icon styling */
.icon {
    width: 20px;
    height: 20px;
}

/* Button container styling */
.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    gap: 0.5rem;
}

/* Visibility toggle button */
.visibilityToggle {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
}

.visibilityToggle .icon {
    width: 16px;
    height: 16px;
    color: #333;
    transition: color 0.2s ease;
}

.visibilityToggle:hover .icon {
    color: #007BFF;
}

/* Overlays */
.loadingOverlay,
.newOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.loadingOverlay {
    background-color: rgba(255, 255, 255, 0.7);
}

.newOverlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.newText {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
}

/* Loading spinner */
.loadingSpinner {
    width: 30px;
    height: 30px;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
    :root {
        --square-size: 100px;
        /* Smaller squares on small screens */
        --gap-size: 0.2rem;
    }


    .container {
        height: calc((100px * 1.2) + 2 * 0.2rem);
        padding: 0.1rem;
        width: 95%;
        max-width: none;
    }


}