.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background: #ffffff
}

.privacy-policy h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.privacy-policy h2 {
    font-size: 1.8em;
    margin-top: 30px;
    margin-bottom: 15px;
}

.privacy-policy p {
    margin-bottom: 15px;
}

.privacy-policy .last-updated {
    font-style: italic;
    opacity: 0.7;
    /* Slightly dim the text instead of using a specific color */
    margin-bottom: 30px;
}

.privacy-policy ul {
    padding-left: 20px;
    margin-bottom: 15px;
}

.privacy-policy li {
    margin-bottom: 10px;
}

.privacy-policy section {
    margin-bottom: 30px;
}