.menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* 50/50 split */
    gap: 16px;
    width: 100%;
    height: 100%;
    /* Optional: Add some space between the columns */
}

.menu-container {
    position: absolute;
    z-index: 40;
    top: 30px;
    right: 20px;
    width: 20%;
    /* Optional: Add some space between the columns */
}

.menu-first {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* Optional: Align items vertically in the center */
    /* Optional: Add some space between the elements */
}


.menu-first h2 {
    font-size: 26px;
    margin: 0;
    /* Optional: Align items vertically in the center */
    /* Optional: Add some space between the elements */
}